import {
  Box,
  HStack,
  theme,
  Text,
  Heading,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BiAlarmExclamation } from 'react-icons/bi';
import { BiErrorAlt } from 'react-icons/bi';
import { color, textStyle } from "theme/names";
import { useRouter } from "next/dist/client/router";

export const NoticePanel = ({
  title = "" as any,
  body,
  icon = <BiAlarmExclamation />,
  type = "info",
}) => {
  const router = useRouter();
  let bg = useColorModeValue(color["info.lightMode.100"], color["info.darkMode.100"]);
  const errorBg = useColorModeValue(color["alert.lightMode.100"], color["alert.darkMode.100"]);
  const dangerBg = useColorModeValue(color["alert.lightMode.200"], color["alert.darkMode.200"]);
  const borderColor = useColorModeValue(theme.colors.gray[50], theme.colors.gray[800]);
  const visibility = useBreakpointValue([false, true])

  if (type == "error") bg = errorBg;
  if (type == "danger") bg = dangerBg;

  if (router?.pathname?.includes("/write")) {
    return <></>;
  }

  return body ? (
    <>
      {visibility && <Box
        bg={bg}
        w="100%"
        p={3}
        pl={{ base: 16, lg: 6 }}
        borderBottom={
          "solid 1px " + borderColor}
      >
        {title && (
          <HStack>
            {type == "error" ? <BiErrorAlt /> : icon}
            <Heading size="sm">{title}</Heading>
          </HStack>
        )}
        <HStack>
          <Box>
            {Array.isArray(body) ? (
              body.map((item, index) => (
                <Text
                  textStyle={textStyle["body.medium.standard"]}
                  key={index}
                  sx={{ fontSize: "13px" }}
                  pl={6}
                  mt={1}
                  as={Box}
                >
                  {item}
                </Text>
              ))
            ) : (
              <Text
                textStyle={textStyle["body.medium.standard"]}
                sx={{ fontSize: "13px" }}
                pl={6}
                mt={1}
                as={Box}
              >
                {body}
              </Text>
            )}
          </Box>
        </HStack>
      </Box>
      }
    </>
  ) : null;
}