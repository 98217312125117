import {
  Box,
  Flex,
  IconButton,
  Link,
  VisuallyHidden,
  Icon,
} from "@chakra-ui/react";
import { Logo, SvgLogoIcon } from "components/logo";
import { setInLocalStorage } from "utils/helpers";
import { SidebarDivider } from "./SidebarDivider";
import { LemSwitcher } from "../page/LemSwitcher";
import { useEffect } from "react";

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  toggleMenu: () => void;
  isMenuOpen: boolean;
  isHovered: boolean;
  setIsHovered: (value: boolean) => void;
}

export const Header = ({
  navSize,
  changeNavSize,
  toggleMenu,
  isMenuOpen,
  isHovered,
  setIsHovered,
}: Props) => {
  const handleToggleMenu = () => {
    const newSize: "small" | "large" = navSize === "large" ? "small" : "large";
    changeNavSize(newSize);
    setInLocalStorage("navSize", newSize);
    toggleMenu();
  };

  useEffect(() => {
    if (isMenuOpen) {
      setIsHovered(false);
    }
  }, [isMenuOpen]);

  return (
    <Box>
      <Flex
        alignItems="center"
        justifyContent={navSize === "large" ? "start" : "center"}
        height="66px"
        onMouseEnter={() => {
          if (!isMenuOpen) setIsHovered(true);
        }}
        onMouseLeave={() => setIsHovered(false)}
        position="relative"
      >
        {!isHovered && (
          <IconButton
            aria-label="Toggle Menu"
            icon={<SvgLogoIcon />}
            onClick={handleToggleMenu}
            position={"absolute"}
            top={"16px"}
            left={"14px"}
            variant="invisible"
            justifyContent={"start"}
            w={"32px"}
            h={"32px"}
          />
        )}
        {isMenuOpen && <LemSwitcher />}
        {isMenuOpen && navSize === "large" && (
          <IconButton
            aria-label="Close Menu"
            style={{ padding: "15px" }}
            icon={
              <div
                style={{
                  transform: "rotate(180deg)",
                  transition: "transform 0.3s ease",
                }}
              >
                <Icon viewBox="0 0 14 11" width={"20px"}>
                  <svg
                    width="14"
                    height="11"
                    viewBox="0 0 14 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                      fill="currentColor"
                    />
                  </svg>
                </Icon>
              </div>
            }
            onClick={toggleMenu}
            position={"absolute"}
            top={"20px"}
            left={"calc(100% - 50px)"}
            variant="ghost"
            justifyContent={"center"}
            w={"24px"}
            h={"24px"}
            bg="transparent"
            borderRadius="3px"
            _hover={{
              backgroundColor: "transparent",
            }}
            _active={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          />
        )}

        {isHovered && navSize === "small" && !isMenuOpen && (
          <IconButton
            aria-label="Open Menu"
            icon={
              <Icon viewBox="0 0 14 11" width={"20px"}>
                <svg
                  width="14"
                  height="11"
                  viewBox="0 0 14 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                    fill="currentColor"
                  />
                </svg>
              </Icon>
            }
            onClick={handleToggleMenu}
            position={"absolute"}
            top={"16px"}
            left={"14px"}
            variant="ghost"
            justifyContent={"center"}
            w={"30px"}
            h={"30px"}
            bg="transparent"
            borderRadius="3px"
            _hover={{
              backgroundColor: "transparent",
            }}
            _active={{
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            }}
          />
        )}

        <Link
          display={navSize === "small" ? "none" : "flex"}
          href="/queue"
          _hover={{ textDecoration: "none" }}
          alignSelf="end"
          position={"absolute"}
          top={"22px"}
          left={"56px"}
          height="30px"
        >
          <VisuallyHidden>Taplio</VisuallyHidden>
          <Logo
            overrideSmallerThan600={true}
            h="26px"
            preserveText={true}
            textOnly
            ml="-8px"
          />
        </Link>
      </Flex>
      <SidebarDivider navSize={navSize}></SidebarDivider>
    </Box>
  );
};
