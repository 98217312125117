import React from "react";
import {
  Link,
  MenuItem,
  Grid,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { color, textStyle } from "theme/names";

const checkmarkSVG = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7893 4.64062C19.2971 5.10938 19.2971 5.92969 18.7893 6.39844L8.78931 16.3984C8.32056 16.9062 7.50024 16.9062 7.03149 16.3984L2.03149 11.3984C1.52368 10.9297 1.52368 10.1094 2.03149 9.64062C2.50024 9.13281 3.32056 9.13281 3.78931 9.64062L7.89087 13.7422L17.0315 4.64062C17.5002 4.13281 18.3206 4.13281 18.7893 4.64062Z"
      fill="currentColor"
    />
  </svg>
);

export const LemSwitcherMenuItem = ({
  title,
  subTitle,
  icon,
  iconHover = icon,
  href,
  current = false,
}) => {
  const [hover, setHover] = React.useState(false);
  const checkMarkColor = useColorModeValue(
    hover ? "white" : color["text.lightMode.light"],
    hover ? "white" : color["text.darkMode.light"]
  );

  const backgroundColor = current
    ? "var(--chakra-colors-primary-lightMode-default)"
    : hover
    ? "var(--chakra-colors-primary-lightMode-default)"
    : "transparent";

  return (
    <Link
      {...{
        href: href + "?utm_source=lemapp_taplio",
        target: "_blank",
        variant: "noUnderline",
      }}
    >
      <MenuItem
        {...{
          onMouseEnter: () => setHover(true),
          onMouseLeave: () => setHover(false),
          bg: backgroundColor,
        }}
      >
        <Grid
          {...{
            gridTemplateColumns: current ? "auto 1fr auto" : "auto 1fr",
            w: "full",
            gap: "10px",
          }}
        >
          {hover ? iconHover : icon}
          <Grid
            {...{
              gridTemplateRows: "auto auto",
            }}
          >
            <Text
              {...{
                _active: { color: "white" },
                _focus: { color: "white" },
                textStyle:
                  hover || current
                    ? textStyle["h4.bold.white"]
                    : textStyle["h4.bold.standard"],
              }}
            >
              {title}
            </Text>
            <Text
              {...{
                textStyle:
                  hover || current
                    ? textStyle["body.medium.white"]
                    : textStyle["body.medium.light"],
                _active: { color: "white" },
                _focus: { color: "white" },
              }}
            >
              {subTitle}
            </Text>
          </Grid>
          {/* white checkmark */}
          {current && (
            <Icon
              {...{
                placeSelf: "center",
                fontSize: "20px",
                color: hover || current ? "white" : checkMarkColor,
                ml: 2,
              }}
            >
              {checkmarkSVG}
            </Icon>
          )}
        </Grid>
      </MenuItem>
    </Link>
  );
};
