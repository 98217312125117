import { useSession } from "next-auth/react";
import {
  Flex,
  Image,
  Heading,
  Text,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useState, useRef, useCallback } from "react";
import { getAccount } from "utils/sessionHelper";
import { AccountSwitcher } from "components/account";
import { SmallSidebarProfileMenu } from "./SmallSidebarProfileMenu";

interface Props {
  navSize: "small" | "large";
}

export const Account = ({ navSize }: Props) => {
  const { data: session, status } = useSession() ?? {};
  const loading = status === "loading";

  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef<HTMLDivElement>(null);

  const toggleProfileMenu = useCallback(() => {
    setProfileMenuOpen((prev) => !prev);
  }, []);

  const closeProfileMenu = useCallback(() => {
    setTimeout(() => setProfileMenuOpen(false), 100);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <>
      <Flex m={2} justifyContent={"space-between"}>
        <Flex
          my="9px"
          h="40px"
          w="40px"
          justifyContent={navSize === "large" ? "flex-start" : "center"}
          display={navSize === "small" ? "flex" : "none"}
        >
          <Image
            w="10"
            h="10"
            rounded="3xl"
            objectFit="cover"
            src={getAccount(session)?.image}
            fallbackSrc="/emptyProfile.png"
            ignoreFallback={false}
            onClick={toggleProfileMenu}
          />
          {!loading && session?.user && (
            <Box ref={profileMenuRef}>
              {isProfileMenuOpen && (
                <SmallSidebarProfileMenu
                  disableMenu={false}
                  user={session?.user}
                  isProfileMenuOpen={isProfileMenuOpen}
                  onProfileMenuClose={closeProfileMenu}
                />
              )}
            </Box>
          )}
        </Flex>
        <Box display={navSize === "small" ? "none" : "flex"} w="100%">
          {!loading && session?.user && (
            <AccountSwitcher
              disableMenu={false}
              disablePhoto={false}
              user={session?.user}
            />
          )}
        </Box>
      </Flex>

      {(session?.user?.data?.isGhost || session?.user?.data?.isAdmin) &&
        getAccount(session)?.ghostData?.length && (
          <Modal
            isOpen={isProfileMenuOpen}
            onClose={closeProfileMenu}
            size="xl"
            isCentered
          >
            <ModalOverlay />
            <ModalContent maxH="800px" overflow="scroll">
              <ModalHeader>
                Information about @{getAccount(session)?.twUserName}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={5}>
                {getAccount(session)?.ghostData.map((data, index) => (
                  <Box mt={5} key={index}>
                    <Heading fontSize="sm">{data.title}</Heading>
                    <Text>{data.data}</Text>
                  </Box>
                ))}
              </ModalBody>
            </ModalContent>
          </Modal>
        )}
    </>
  );
};
