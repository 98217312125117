import {
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
  Flex,
  Box,
  Button,
  Image,
  Text,
  Input,
  IconButton,
  useDisclosure,
  Portal,
} from "@chakra-ui/react";
import * as React from "react";
import { useRouter } from "next/dist/client/router";
import { useSession } from "next-auth/react";
import { FiSettings } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { getPlan } from "../../controllers/subscription";
import { firebaseClient, getToken } from "firebaseClient";
import toast from "react-hot-toast";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useFirebaseUser } from "../../utils/useFirebaseUser";
import { getAccount, selectAccount, logout } from "utils/sessionHelper";
import { ConfirmPopup } from "../popups/confirmPopup";
import { HiOutlineLogout } from "react-icons/hi";
import { NavSectionTitle } from "components/sidebar/mainNavItems";
import { useWindowWidth } from "@react-hook/window-size";
import { variant } from "theme/names";

const SmallSidebarProfileMenuItem = ({ onClick, label, icon }) => (
  <MenuItem
    onClick={() => {
      onClick();
    }}
    rounded="md"
    my={0}
    py={2}
    px={1}
    {...{
      color: "text.lightMode.light",
      _hover: {
        color: "text.lightMode.standard",
        bg: "none",
      },
      _dark: {
        color: "text.darkMode.light",
        bg: "none",
        _hover: {
          color: "text.darkMode.standard",
        },
      },
    }}
    icon={icon}
  >
    <Text fontSize="sm" fontWeight={500}>
      {label}
    </Text>
  </MenuItem>
);

export const SmallSidebarProfileMenu = ({
  user,
  disableMenu,
  onProfileMenuClose,
  isProfileMenuOpen,
}) => {
  const { data: session } = useSession() ?? {};
  const [customSearch, setCustomSearch] = React.useState("");
  const isAuthenticated = useFirebaseUser();
  const [refresh, refresher] = React.useState({});

  const [selectedAccount, setSelectedAccount] = React.useState<any>({});
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const { isOpen: isOpenConfirmAdd, onClose: onCloseConfirmAdd } =
    useDisclosure();

  const router = useRouter();

  let accounts = session?.user?.data?.accounts?.filter(
    (x) => x.idAccount != session.user.uid && x.provider == "twitter"
  );

  React.useEffect(() => {
    if (isAuthenticated && accounts) {
      accounts?.forEach(async (acc) => {
        if (acc.needInit && acc.id !== session?.user?.uid) {
          selectAccount(session, acc, "owned");
          acc.needInit = false;
          const db = firebaseClient.firestore();
          await db
            .collection("users")
            .doc(session?.user?.uid)
            .collection("accounts")
            .doc(acc.id)
            .update({ needInit: false });
          router.push("/onboarding");
        } else if (acc.needInit) {
          const db = firebaseClient.firestore();
          await db
            .collection("users")
            .doc(session?.user?.uid)
            .collection("accounts")
            .doc(acc.id)
            .update({ needInit: false });
        }
      });
    }
  }, [isAuthenticated, session]);

  const textStandard = useColorModeValue(
    "text.lightMode.standard",
    "text.darkMode.standard"
  );
  const textLight = useColorModeValue(
    "text.lightMode.light",
    "text.darkMode.light"
  );
  const bg = useColorModeValue("white", "#1E1E1E");
  const borderColor = useColorModeValue(
    "border.lightMode.light",
    "border.darkMode.light"
  );

  let nbSeats = session?.user?.data?.members?.length ?? 1;

  const getItem = (acc, session, type = "shared", showSeats = false) => {
    return (
      <MenuItem
        key={"acc-" + acc.id}
        position="relative"
        onClick={async (e) => {
          if (acc.idParent === session.user.uid) {
            console.log("going to main account");
            session.user.selectedAccount = undefined;
            session.user.selectedAccountId = "";
            session.user.selectedAccountType = "";
            localStorage.removeItem("selectedAccountId");
            localStorage.removeItem("selectedAccount");
            localStorage.removeItem("selectedAccountType");
          } else {
            console.log("going to shared account");
            selectAccount(session, acc, type);
          }

          location.reload();
        }}
        borderRadius="8px"
        mb={2}
        py={1}
        px={1}
        bg="none"
        border={"1px solid"}
        borderColor={"border.lightMode.light"}
        _hover={{
          borderColor: "border.lightMode.hover",
        }}
        _dark={{
          borderColor: "border.darkMode.light",
          _hover: {
            borderColor: "border.darkMode.hover",
          },
        }}
      >
        {["owned", "shared"].includes(type) &&
          !acc?.isFromOrg &&
          acc.id !== getAccount(session)?.idAccount && (
            <IconButton
              as={Box}
              minW="24px"
              variant="secondary"
              size="xs"
              aria-label="delete account"
              icon={<ImCross fontSize="8px" />}
              position="absolute"
              right="5px"
              zIndex="10"
              onClick={async (e) => {
                e.stopPropagation();
                if (session?.user?.uid !== getAccount(session)?.id) {
                  toast.error(
                    "Switch to your main account to remove shared accounts"
                  );
                  return;
                }
                setSelectedAccount(acc);
                onOpenConfirmDelete();
              }}
            />
          )}
        <Image
          w="10"
          h="10"
          rounded="3xl"
          objectFit="cover"
          src={acc.image}
          fallbackSrc="/emptyProfile.png"
        />
        <Flex flexDirection="column" ml={2}>
          <Text
            noOfLines={1}
            wordBreak={"break-all"}
            as="h2"
            fontSize="xs"
            color={textStandard}
            w="138px"
            flex={1}
            fontWeight={600}
          >
            {acc.name}
          </Text>
          {acc.twUserName && (
            <Text
              noOfLines={1}
              wordBreak={"break-all"}
              w="138px"
              color={textLight}
              fontSize="xs"
            >
              @{acc.twUserName}
            </Text>
          )}
          <Text color={textLight} fontSize="xs" textAlign={"start"}>
            {showSeats && `${nbSeats} Seat${nbSeats > 1 ? "s" : ""}`}
          </Text>
        </Flex>
      </MenuItem>
    );
  };

  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;

  return (
    <Menu isOpen={isProfileMenuOpen} onClose={onProfileMenuClose}>
      <Portal>
        {!disableMenu && (
          <MenuList
            background="none"
            border="none"
            shadow="none"
            minW={isTooSmall ? "100vw" : "calc(350px - 0px)"}
            mb={1}
            maxH="80vh"
            overflowY="auto"
          >
            <Box
              position="fixed"
              bottom={"30px"}
              left={"70px"}
              mb={1}
              fontSize={"sm"}
              color={"text.lightMode.light"}
              _dark={{
                color: "text.darkMode.light",
              }}
              shadow="2xl"
              p="2"
              ml={2}
              borderRadius={"md"}
              border={"1px solid"}
              borderColor={borderColor}
              bg={bg}
              margin="auto"
              maxW={isTooSmall ? "calc(100vw - 18px)" : "calc(250px - 18px)"}
              overflowY="auto"
            >
              {getItem(
                session?.user?.data?.accounts?.[0] ?? session?.user?.data,
                session,
                "shared",
                true
              )}
              <SmallSidebarProfileMenuItem
                {...{
                  onClick: () => {
                    router.push("/settings");
                  },
                  label: "Account settings",
                  icon: <FiSettings fontSize="16px" />,
                }}
              ></SmallSidebarProfileMenuItem>
              {accounts?.length > 0 && (
                <>
                  <NavSectionTitle
                    {...{
                      title: "YOUR ACCOUNTS",
                      navSize: "large",
                      compact: true,
                    }}
                  ></NavSectionTitle>
                  {accounts?.map((acc) => getItem(acc, session, "owned"))}
                </>
              )}
              {session?.user?.linkAccounts?.filter((x) => !x.isFromOrg)
                ?.length > 0 && (
                <>
                  <NavSectionTitle
                    {...{
                      title: "SHARED ACCOUNTS",
                      navSize: "large",
                      compact: true,
                    }}
                  ></NavSectionTitle>
                  {session?.user?.linkAccounts
                    ?.filter((x) => !x.isFromOrg)
                    ?.map((acc) => getItem(acc, session))}
                </>
              )}
              <NavSectionTitle
                {...{
                  title: "YOUR ORGANIZATION",
                  navSize: "large",
                  compact: true,
                }}
              />
              {session?.user?.linkAccounts?.filter((x) => x.isFromOrg)?.length >
                0 &&
                session?.user?.linkAccounts
                  ?.filter((x) => x.isFromOrg)
                  ?.map((acc) => getItem(acc, session))}
              {(!session?.user?.data?.subscription?.isInOrg ||
                session?.user?.data?.subscription?.orgRole === "Admin") && (
                <SmallSidebarProfileMenuItem
                  {...{
                    onClick: () => {
                      router.push("/settings?tab=team");
                    },
                    label: "Invite team members",
                    icon: <AiOutlinePlusCircle fontSize="16px" />,
                  }}
                ></SmallSidebarProfileMenuItem>
              )}
              {session?.user?.data.isAdmin && (
                <Flex w="200px">
                  <Input
                    placeholder="email, id or username"
                    value={customSearch}
                    onChange={(e) => {
                      setCustomSearch(e.target.value);
                    }}
                  />
                  <Button
                    variant="secondary"
                    as={Box}
                    ml={2}
                    onClick={async () => {
                      console.log("look for " + customSearch);
                      toast.promise(
                        new Promise(async (resolve, reject) => {
                          if (
                            session?.user?.data?.impersonatingOriginUser?.id
                          ) {
                            reject(
                              "You can't impersonate another user while impersonating another user"
                            );
                            return;
                          }

                          let response = await fetch("/api/impersonate/start", {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                              tokenUserId: session.user.uid,
                              token: await getToken(session, "checkTweetValid"),
                              userToImpersonate: customSearch,
                            }),
                          });
                          let data = await response.json();

                          if (data?.success) {
                            location.reload();
                            resolve(user);
                          } else {
                            reject(data.error ?? "An error occured");
                          }
                        }),
                        {
                          loading: "loading ... ",
                          success: "Success",
                          error: (err) =>
                            err ? err.toString() : "An error occured.",
                        }
                      );
                    }}
                  >
                    Go
                  </Button>
                </Flex>
              )}
              {(session?.user?.linkAccounts?.length > 0 ||
                session?.user?.data?.accounts?.length > 0) && <MenuDivider />}
              <SmallSidebarProfileMenuItem
                {...{
                  onClick: () => {
                    logout();
                  },
                  label: "Logout",
                  icon: (
                    <HiOutlineLogout
                      fontSize="16px"
                      style={{ marginLeft: "4px", marginRight: "-2px" }}
                    />
                  ),
                }}
              ></SmallSidebarProfileMenuItem>
              {session?.user?.data?.subscription?.isSubscribed || true ? (
                getPlan(session?.user) == "start" ? (
                  <>
                    <MenuDivider />
                    <Button
                      as={Box}
                      variant={variant.Button.primary}
                      onClick={() => {
                        router.push("/pricing");
                      }}
                      css={{
                        width: "100%",
                      }}
                    >
                      Upgrade
                    </Button>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  <MenuDivider />
                  <Button
                    as={Box}
                    variant={variant.Button.primary}
                    onClick={() => {
                      router.push("/pricing");
                    }}
                    css={{
                      width: "100%",
                    }}
                  >
                    Start Free Trial
                  </Button>
                </>
              )}
            </Box>
          </MenuList>
        )}
      </Portal>
      <ConfirmPopup
        isOpen={isOpenConfirmDelete}
        onClose={onCloseConfirmDelete}
        title="Please confirm account removal"
        body={
          <>
            You’re about to remove the account:{" "}
            <b>
              {selectedAccount.twUserName
                ? "@" + selectedAccount.twUserName
                : selectedAccount.name}
            </b>
          </>
        }
        callback={async () => {
          if (selectedAccount?.id) {
            if (selectedAccount.type == "owned") {
              const db = firebaseClient.firestore();
              await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("accounts")
                .doc(selectedAccount.id)
                .delete();
              let docTweets = await db
                .collection("users")
                .doc(session?.user?.uid)
                .collection("tweets")
                .where("idAccount", "==", selectedAccount.id)
                .get();
              console.log("nb tweets to delete: " + docTweets.docs.length);
              docTweets.forEach((doc) => {
                console.log("delete tweets " + doc.id);
                db.collection("users")
                  .doc(session?.user?.uid)
                  .collection("tweets")
                  .doc(doc.id)
                  .delete();
              });
              await new Promise((resolve) => setTimeout(resolve, 1000));
              location.reload();
            } else {
              let dataToSend = {
                idUser: selectedAccount.id,
                mode: "delete",
                idGhost: getAccount(session).id,
                tokenUserId: session?.user?.uid,
                token: await getToken(session, "sidebar-share-delete"),
              };
              let response = await fetch("/api/shareAccount", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(dataToSend),
              });
              let data = await response.json();
              if (data?.success && data.shareAccountsTo) {
                getAccount(session).shareAccountsTo = data.shareAccountsTo;
                refresher({});
                await new Promise((resolve) => setTimeout(resolve, 1000));
                location.reload();
              } else {
                toast.error("An error occured: " + data?.error);
              }
            }
          }
        }}
      />
      <ConfirmPopup
        isOpen={isOpenConfirmAdd}
        onClose={onCloseConfirmAdd}
        title="Add a new account"
        body={
          "Please, make sure you are signed in on Twitter with the account you want to add"
        }
        cta="Connect"
        ctaProps={{
          as: "a",
          href: "#",
          target: "_blank",
        }}
        callback={async () => {}}
      />
    </Menu>
  );
};
