import React from "react";
import { Icon } from "@chakra-ui/react";

export const LemWarmIcon = ({ ...props }) => (
  <Icon
    {...{
      width: 42,
      height: 43,
      viewBox: "0 0 42 43",
      ...props,
    }}
  >
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5211 0.5H7.4789C3.34842 0.5 0 3.84842 0 7.9789V35.0211C0 39.1516 3.34842 42.5 7.4789 42.5H34.5211C38.6516 42.5 42 39.1516 42 35.0211V7.9789C42 3.84842 38.6516 0.5 34.5211 0.5Z"
        fill="#FFB866"
      />
      <path
        d="M21.7717 13.4999H20.2266C19.6177 13.4999 19.1241 13.9935 19.1241 14.6023V22.3969C19.1241 23.0058 19.6177 23.4994 20.2266 23.4994H21.7717C22.3806 23.4994 22.8742 23.0058 22.8742 22.3969V14.6023C22.8742 13.9935 22.3806 13.4999 21.7717 13.4999Z"
        fill="white"
      />
      <path
        d="M30.7499 12.8526V26.9319C30.6018 29.2497 28.7479 31.1018 26.4319 31.2499H15.5787C13.1622 31.0947 11.2501 29.0856 11.2501 26.6303V12.8526C11.2501 12.244 11.744 11.7501 12.3526 11.7501H13.8977C14.5063 11.7501 15.0002 12.244 15.0002 12.8526V24.6336C15.0002 26.2176 16.2825 27.4999 17.8665 27.4999H24.1336C25.7158 27.4999 26.9999 26.2176 26.9999 24.6336V12.8526C26.9999 12.244 27.4938 11.7501 28.1023 11.7501H29.6475C30.256 11.7501 30.7499 12.244 30.7499 12.8526Z"
        fill="white"
      />
    </svg>
  </Icon>
);
