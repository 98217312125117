import { useSession } from "next-auth/react";

const godUsers = [
  "tristan@lempire.co",
  "tristan@taplio.co",
  "manoj@lempire.co",
  "manoj.sachwani@gmail.com",
  "osama@lempire.com",
  "malte@lempire.com",
  "volodymyr@lempire.com"
];

export const isGodUser = () => {
  const { data: session }: any = useSession();
  const isGod = godUsers.includes(session?.user?.email);
  return isGod;
};