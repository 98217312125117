import {
  Box, Button, Flex, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalHeader, ModalOverlay, useColorModeValue
} from "@chakra-ui/react";
import * as Bowser from "bowser";
import { ModalInfoIcon } from "components/popups/ModalInfoIcon";
import { firebaseClient } from "firebaseClient";
// import Gleap from 'gleap';
import { useSession } from "next-auth/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FiChrome, FiSettings } from "react-icons/fi";
import { IconType } from "react-icons/lib";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { variant } from "theme/names";
import { getAccount } from "utils/sessionHelper";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { Account } from "./account";
import { HelpCenterSVGIcon } from "./icons/HelpCenterSVGIcon";
import { NavItem } from "./navItem";

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  isOpenWalkthrough: boolean;
  onOpenWalkthrough: () => void;
  onCloseWalkthrough: () => void;
  withShadowFooter?: boolean;
}

export function Footer({
  navSize,
  changeNavSize,
  isOpenWalkthrough,
  onOpenWalkthrough,
  onCloseWalkthrough,
  withShadowFooter = false,
}: Props) {
  const [isChrome, setIsChrome] = useState<boolean>(false);
  const { data: session, status } = useSession() ?? {}
  const loading = status === "loading"
  const router = useRouter();
  const isAuthenticated = useFirebaseUser();

  const universityIconHoverColor = useColorModeValue("gray.100", "gray.700");
  const universityIconColor = useColorModeValue("gray.600", "gray.50");
  const helpButtonHoverBg = useColorModeValue("gray.100", "gray.700");
  const helpButtonColor = useColorModeValue("gray.600", "gray.50");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const browser = Bowser.getParser(window.navigator.userAgent);
      setIsChrome(browser?.getBrowserName()?.includes("chrome"));
    }
  }, []);

  useEffect(() => {
    // onOpenWalkthrough();
    if (
      isAuthenticated &&
      session?.user?.data?.needToShowWalkthrough &&
      (router.pathname.includes("auto") || router.pathname.includes("queue"))
    ) {
      onOpenWalkthrough();
      const db = firebaseClient.firestore();
      db.collection("users")
        .doc(session?.user?.uid)
        .update({ needToShowWalkthrough: false });
      session.user.data.needToShowWalkthrough = false;
    }
  }, [session, isAuthenticated]);

  if (loading) {
    return null;
  }

  let showExtension = !getAccount(session)?.idLinkedin;
  // let showExtension = true;
  // console.log({withShadowFooter});

  return (
    <Box {...{
      layerStyle: withShadowFooter && navSize === "large" ? "footerShadowTop" : undefined
    }}>
      <Box {...{
        mx: navSize === "small" ? 0 : 3, mt: 1,
      }}>
        <NavItem
          title="Account Settings"
          href="/settings"
          icon={(<FiSettings fontSize="16px" />) as unknown as IconType}
          navSize={navSize}
          iconStroke="1"
        />
        <NavItem
          title="Help Center & Support"
          leastCommonContainerProps={{
            onClick: () => {
              // Gleap.open();
              try {
                //@ts-ignore
                window.Intercom("showNewMessage");
              }
              catch (e) {
                console.log("error in intercom", e);
              }
            },
          }}
          icon={HelpCenterSVGIcon as unknown as IconType}
          navSize={navSize}
        />
        {showExtension && (
          // true && (
          <Flex
            justifyContent={navSize == "large" ? "flex-start" : "center"}
            py="0.5"
          >
            {navSize === "small" ? (
              <IconButton
                variant={variant.Button.primary}
                aria-label="connect with linkedin"
                icon={<FiChrome fontSize={"16px"} />}
                size="sm"
              />
            ) : (
              <NextLink href="/connect" passHref>
                <Button
                  variant={variant.Button.primary}
                  w="full"
                  size="sm"
                  leftIcon={<FiChrome fontSize={"16px"} />}
                >
                  Install Chrome Extension
                </Button>
              </NextLink>
            )}
          </Flex>
        )
          // : (
          //   <Flex justifyContent="center" w="100%" pt="1">
          //     {navSize === "small" ? (
          //       <IconButton
          //         variant="outline"
          //         colorScheme="linkedin"
          //         aria-label="connect with linkedin"
          //         icon={<AiOutlineDollar fontSize={"16px"} />}
          //         size="sm"
          //       />
          //     ) : (
          //       <Button
          //         size="sm"
          //         w={"140px"}
          //         ml={2}
          //         leftIcon={<AiOutlineDollar size="16" />}
          //         background="linear-gradient(96.86deg, #1569E6 3.49%, #0248AE 94.63%)"
          //         color="white"
          //         colorScheme="ghost"
          //         fontSize="12px"
          //         as="a"
          //         href="https://affiliates.taplio.com/"
          //         target="_blank"
          //       >
          //         Affiliate Program
          //       </Button>
          //     )}
          //   </Flex>
          // )
        }
        {/* </VStack> */}
      </Box>

      <Account navSize={navSize} />
      <Modal
        isOpen={isOpenWalkthrough}
        onClose={onCloseWalkthrough}
        size="4xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
        // p={0}
        // boxShadow="rgba(100, 100, 100, 1) 0px 0px 30px 20px;"
        >
          <ModalHeader>
            {/* <ModalInfoIcon /> */}
            Don't start yet
          </ModalHeader>
          <ModalCloseButton
          // position="absolute"
          // top="-30px"
          // right="-30px"
          // color="white"
          />
          <ModalBody>
            <LiteYouTubeEmbed
              id="08aqQyCPACs"
              title="Taplio walkthrough"
              announce="Watch"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
