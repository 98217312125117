import React, { ReactNode, useEffect, useState } from "react";
import {
  useColorModeValue as mode,
  useMediaQuery,
  IconButton,
  Box,
  useColorModeValue,
  VStack,
  theme,
  Tag,
  Link,
  Flex,
  Button,
  useDisclosure,
  Icon,
  BoxProps,
  FlexProps,
  Heading,
  Tooltip,
  Text,
  Badge,
} from "@chakra-ui/react";
import * as analytics from "../../utils/analytics";
import { useSession } from "next-auth/react";
import { Footer } from "components/footer";
import Sidebar from "components/sidebar";
import Scheduler from "components/scheduler";
import { CustomHead } from "components/custom-head";
import { useRouter } from "next/dist/client/router";
import { FiFeather } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { motion } from "framer-motion";
import { TweetContext } from "../../context/tweetContext";
import { OnBoardPanel } from "../onboard-panel";
import { setInLocalStorage } from "../../utils/helpers";
import { useMiscellaneousContext } from "context/miscellaneousContext";
import { NoticePanel } from "./noticepanel";
import { confirmApiBridgeLoad } from "utils/useApi";
import { useWindowWidth } from "@react-hook/window-size";
import { connectLinkedin } from "../../controllers/linkedinController";
import {
  getAccount,
  getAuthUrl,
  stopImpersonating,
  updateUser,
} from "../../utils/sessionHelper";
// import Gleap from "gleap";
import { textStyle, variant } from "theme/names";
import { WriteAPostSVGIcon } from "components/sidebar/icons/WriteAPostSVGIcon";
import { BsLightningChargeFill } from "react-icons/bs";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { ReactElement } from "react-markdown/lib/react-markdown";
import { IconType } from "react-icons/lib";
import { TextProps } from "recharts";
import { useFirebaseUser } from "utils/useFirebaseUser";
import { getPlan } from "controllers/subscription";
import NextLink from "next/link";
import { useChatContext } from "context/chatContext";
import { AnimatedBg } from "components/sidebar/AnimatedBg";
import { AiIcon } from "components/sidebar/icons/AiIcon";
import { AiIconButton } from "components/common/AiButton/AiButton";
import { LoadingBar } from "./LoadingBar";
import { useSearchParams } from "next/navigation";
import { isGodUser } from "utils/isGod";
interface Props {
  children: ReactNode;
  forceCloseScheduler?: boolean;
  isMinimalist?: boolean;
  disableSidebar?: boolean;
  leftIcon?: ReactElement;
  title?: ReactNode | string;
  description?: string;
  infoIcon?: IconType | typeof Icon;
  infoTooltipText?: string;
  contentOnRightOfTitle?: ReactNode;
  contentBesidesTitle?: ReactNode;
  mainWrapperProps?: BoxProps;
  descriptionWrapperProps?: any;
  titleIconWrapperProps?: FlexProps;
  titleRowWrapperProps?: FlexProps;
  noIndex?: boolean;
  refresher?: any;
  showLoadingBar?: boolean;
  isLoadingFeature?: boolean;
}

export const Page = ({
  children,
  forceCloseScheduler = false,
  disableSidebar = false,
  leftIcon = undefined,
  title,
  description = "",
  infoIcon: InfoIcon = InfoOutlineIcon,
  infoTooltipText = "",
  contentOnRightOfTitle = null,
  contentBesidesTitle = null,
  mainWrapperProps = {},
  descriptionWrapperProps = {},
  titleIconWrapperProps = {},
  titleRowWrapperProps = {},
  isMinimalist = false,
  noIndex = true,
  showLoadingBar = false,
  isLoadingFeature = false,
}: Props) => {
  const { data: session, status } = useSession() ?? {};
  const loading = status === "loading";
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;
  // const [isTooSmall] = useMediaQuery("(max-width: 800px)");
  const [navSize, changeNavSize] = React.useState<"small" | "large">("small");
  const [hasInit, setHasInit] = React.useState(false);
  const [pl, setPl] = React.useState("0px");
  const [pr, setPr] = React.useState("0px");
  const [isRefreshingLinkedin, setIsRefreshingLinkedin] = useState(false);
  const [isExtFound, setIsExtFound] = React.useState<boolean>(false);
  const [hasInitialzedCredits, setHasInitializeCredits] =
    useState<boolean>(false);
  const tweetContext: any = React.useContext(TweetContext);
  const miscellaneousContext: any = useMiscellaneousContext();
  const chatContext: any = useChatContext();
  const [isPageLoading, setIsPageLoading] = useState(isLoadingFeature);
  const [displayLoadingBar, setDisplayLoadingBar] = useState(showLoadingBar);
  const searchParams = useSearchParams();
  const prevUrl = searchParams.get("prevUrl");
  const [isRouted, setIsRouted] = useState(false);
  const isGod = isGodUser();

  const {
    isOpen: isOpenWalkthrough,
    onOpen: onOpenWalkthrough,
    onClose: onCloseWalkthrough,
  } = useDisclosure();
  const router = useRouter();
  const disableScheduler = false;
  const bgHoverIcon = useColorModeValue(
    "primary.lightMode.400",
    "primary.darkMode.400"
  );
  const bgIcon = useColorModeValue(
    "primary.lightMode.default",
    "primary.darkMode.default"
  );
  const isAuthenticated = useFirebaseUser();

  React.useEffect(() => {
    analytics.init(session);
  }, [session]);
  React.useEffect(() => {
    changeNavSize("small");
    let savedSchedulerStatus = localStorage.getItem("isSchedulerOpen");
    if (!isTooSmall && savedSchedulerStatus !== null && !forceCloseScheduler) {
      tweetContext.setIsOpen(savedSchedulerStatus == "true");
    } else if (!isTooSmall && !forceCloseScheduler) {
      tweetContext.setIsOpen(true);
    } else {
      tweetContext.setIsOpen(false);
    }

    // console.log("router.pathname: " + router.pathname);
    if (
      [
        "/pricing",
        "/engage",
        "/onboarding",
        "/collection",
        "public",
        "/add",
        "/onboardnew",
        "/subscribe",
        "/onboardnewchoice",
        "/sign",
        "/signin",
        "/thtweets",
      ].includes(router.pathname)
    ) {
      tweetContext.setIsOpen(false);
    }
  }, []);
  React.useEffect(() => {
    if (!isTooSmall) {
      if (tweetContext.isOpen) {
        setPr("400px");
      } else if (miscellaneousContext.isPersonSidebarOpen) {
        setPr("350px");
      } else {
        setPr("0px");
      }
    } else {
      setPr("0px");
    }
    // console.log("setItem isSchedulerOpen to: " + tweetContext.isOpen);
    //@ts-ignore
    setInLocalStorage("isSchedulerOpen", tweetContext.isOpen);
  }, [
    tweetContext.isOpen,
    miscellaneousContext.isPersonSidebarOpen,
    screenWidth,
  ]);

  // initialize credits
  useEffect(() => {
    if (session && isAuthenticated && !hasInitialzedCredits) {
      setHasInitializeCredits(true);

      if (
        getAccount(session)?.creditsChat === undefined ||
        getAccount(session)?.creditsChat === null
      ) {
        const plan = getPlan(session?.user);
        updateUser(session, {
          creditsChat:
            plan === "enterprise" ? 500000 : plan === "grow" ? 5000 : 0,
        });
      }
    }
  }, [session, isAuthenticated]);

  // useEffect(() => {
  //     // @ts-ignore
  //     if (!hasInit && typeof window !== "undefined" && window?.$crisp?.push && session?.user?.data?.email) {
  //         // @ts-ignore
  //         window?.$crisp?.push(["set", "user:email", session?.user?.data?.email]);
  //         setHasInit(true);
  //     }
  // }, [session]);
  // useEffect(() => {
  //     if (typeof window !== undefined) {
  //         // @ts-ignore
  //         window.$crisp = [];
  //         // @ts-ignore
  //         window.CRISP_WEBSITE_ID = "76d09e0d-78d8-4965-abae-84ac9b1254d7";
  //         (() => {
  //             const d = document;
  //             const s = d.createElement("script");
  //             s.src = "https://client.crisp.chat/l.js";
  //             // @ts-ignore
  //             s.async = 1;
  //             d.getElementsByTagName("body")[0].appendChild(s);
  //             //@ts-ignore
  //             window?.$crisp?.push(["do", "chat:hide"]);
  //             //@ts-ignore
  //             window?.$crisp?.push(["on", "chat:closed", () => {
  //                 //@ts-ignore
  //                 window?.$crisp?.push(["do", "chat:hide"]);
  //             }]);
  //         })();
  //     }
  // }, []);
  React.useEffect(() => {
    if (!isTooSmall) {
      setPl("60px");
    } else {
      setPl("0px");
    }
  }, [navSize, screenWidth]);

  React.useEffect(() => {
    // if (isTooSmall && !isRouted && !paymentFailed) { // TODO: This will cause conflicts on merge with the changes in dev, whoever is merging should make sure this code is included in the merge fix - manoj
    if (isTooSmall && !isRouted && isGod) {
      if (typeof window !== "undefined") {
        const redirectedToMobileLogin = localStorage.getItem("redirectedToMobileLogin") ?? false;
        if (!redirectedToMobileLogin && !prevUrl) {
          setIsRouted(true);
          router.push("/mobilelogin?prevUrl=" + window.location.href);
        }
      }
    }
  }, [navSize, screenWidth, isRouted, prevUrl, isGod]);

  React.useEffect(() => {
    setIsExtFound(confirmApiBridgeLoad());
  });
  React.useEffect(() => {
    try {
      if (isAuthenticated && typeof window !== "undefined") {
        // @ts-ignore
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "nlu9qthh",
          name: session?.user?.data?.name, // Full name
          email: session?.user?.data?.email, // Email address
          hide_default_launcher: true,
        });
      }
    } catch (e) {
      console.error("Error in intercom init: " + e.message);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setDisplayLoadingBar(showLoadingBar);
  }, [showLoadingBar]);

  useEffect(() => {
    setIsPageLoading(isLoadingFeature);
  }, [isLoadingFeature]);

  let SchedulerBtnPos: any = {};
  if (isTooSmall) {
    SchedulerBtnPos.right = "20px";
  } else {
    SchedulerBtnPos.left = "unset";
    SchedulerBtnPos.right = tweetContext.isOpen ? "382px" : "20px";
  }
  const showOnboarding = getAccount(session)?.onboarding?.showPanel;
  const isIdLinkedin = !!getAccount(session)?.idLinkedin;
  const schedulerWidth = 400;
  const leftSideNavWidth = 60;
  const chatAssistpPopupMaxWidth = Math.min(900, screenWidth);
  const wontFit =
    schedulerWidth + leftSideNavWidth + chatAssistpPopupMaxWidth > screenWidth;

  return (
    <Box>
      <CustomHead />
      {!disableSidebar && <Sidebar
        changeNavSize={changeNavSize}
        navSize={navSize}
        credits={session?.user?.data?.credits}
        creditsAuto={session?.user?.data?.creditsAuto}
        isOpenWalkthrough={isOpenWalkthrough}
        onOpenWalkthrough={onOpenWalkthrough}
        onCloseWalkthrough={onCloseWalkthrough}
      />}
      <motion.div
        animate={{
          paddingLeft: pl,
          paddingRight: pr,
        }}
        transition={{
          duration: 0.1,
          ease: "easeOut", // "linear" "easeIn", "easeOut", "easeInOut" "circIn", "circOut", "circInOut" "backIn", "backOut", "backInOut" "anticipate"
        }}
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        {session?.user?.data?.idImpersonating && (
          // (true) &&
          <NoticePanel
            type="danger"
            title={
              <>
                You are impersonating{" "}
                <Link
                  textDecoration="underline"
                  isExternal
                  href={
                    "https://www.linkedin.com/in/" +
                    session?.user?.data?.impersonatingUsername
                  }
                >
                  @{session?.user?.data?.impersonatingUsername}
                </Link>{" "}
                - {session?.user?.data?.impersonatingEmail}
              </>
            }
            body={
              <Flex alignItems="center">
                You are currently accessing an other user account - be careful
                with what you are doing
                <Button
                  size="sm"
                  variant="outline"
                  ml={5}
                  onClick={() => stopImpersonating(session)}
                >
                  Stop Impersonating
                </Button>
              </Flex>
            }
          />
        )}
        {!isIdLinkedin && !tweetContext.isFullModeOpen && (
          // (true) && (
          <NoticePanel
            title="Taplio Extension Not Found"
            body={
              <>
                We couldn’t detect the Taplio Chrome Extension.{" "}
                <Link
                  textDecoration="underline"
                  href={
                    "https://chrome.google.com/webstore/detail/influent-leaders-stats/dfpbcakpogbfaohnnjlgghdjkgaoiaik"
                  }
                  isExternal
                >
                  Please install it
                </Link>{" "}
                and{" "}
                <Link
                  textDecoration="underline"
                  onClick={(e) => window.location.reload()}
                >
                  refresh this page
                </Link>
                . If this problem persists, please uninstall and reinstall the
                extension.
              </>
            }
          />
        )}
        {getAccount(session)?.idAccount &&
          (new Date().getTime() -
            getAccount(session)?.dateLastUpdate?._seconds * 1000) /
            (1000 * 60 * 60 * 24) >
            50 && (
            // (true) &&
            <NoticePanel
              title="You will soon lose access to posting content"
              body={
                <>
                  Your account token is about to expire,{" "}
                  <Link
                    href={"#"} //disable getAuthUrl - manoj - October 2024
                    textDecoration="underline"
                    isExternal
                  >
                    click here to refresh it
                  </Link>
                </>
              }
            />
          )}
        {/* {isAuthenticated && !getAccount(session)?.idLinkedinOfficial && (
          // (true) &&
          <NoticePanel
            type="error"
            title="Connect your Linkedin account to use Taplio"
            body={
              <>
                Taplio needs a Linkedin account connected,{" "}
                <Link
                  href={getAuthUrl(session)}
                  textDecoration="underline"
                  isExternal
                >
                  click here to connect it
                </Link>
              </>
            }
          />
        )} */}
        {getAccount(session)?.idAccount &&
          !getAccount(session)?.accessToken && (
            // (true) && (
            <NoticePanel
              type="error"
              title="You cannot post anymore"
              body={
                <>
                  Your account token has expired,{" "}
                  <Button
                    textDecoration="underline"
                    variant={"link"}
                    size="sm"
                    textColor={"#222"}
                    isLoading={isRefreshingLinkedin}
                    onClick={async () => {
                      console.log("refresh li connection");

                      const accountSetupResult: any = await connectLinkedin(
                        session,
                        setIsRefreshingLinkedin
                      );
                      if (accountSetupResult?.success === 1) {
                        router.reload();
                      }
                    }}
                  >
                    click here to refresh it
                  </Button>
                </>
              }
            />
          )}
        {isIdLinkedin && getAccount(session)?.isLinkedinCookiesInvalidated && (
          // (true) &&
          <NoticePanel
            title="Your Linkedin cookies have expired"
            body={
              <>
                This can prevent some of the Taplio features to work correctly,{" "}
                <Link href={"https://linkedin.com/feed"} isExternal>
                  go to linkedin.com
                </Link>{" "}
                and open the Taplio extension to refresh them
              </>
            }
          />
        )}
        {isAuthenticated &&
          (!getAccount(session)?.keywords?.length ||
            !getAccount(session)?.description) && (
            // (true) &&
            <NoticePanel
              title="AI settings are not properly configured "
              body={
                <>
                  It seems your AI settings are not set. Please,{" "}
                  <NextLink href={"/settings#search-settings"}>
                    <Text as="span" textDecoration="underline">
                      update them here
                    </Text>
                  </NextLink>{" "}
                  to improve the AI generation quality{" "}
                </>
              }
            />
          )}
        {/* {(true) &&
          <NoticePanel
            title="Posting known issues"
              type="error"
              body={
              <>
                The Linkedin API is currently experiencing some instability - Taplio users may be affected
              </>
            }
          />
        } */}
        {showOnboarding && (
          <OnBoardPanel onOpenWalkthrough={onOpenWalkthrough} />
        )}
        {displayLoadingBar && <LoadingBar isLoading={isPageLoading} />}
        {!tweetContext.isFullModeOpen && (
          <Box
            maxW={1200}
            margin="auto"
            px={tweetContext.isOpen ? [8, 8, 8, 8, 16] : [4, 12, 16, 16, 16]}
            py={[12, 12, 8]}
            {...mainWrapperProps}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              {...titleRowWrapperProps}
            >
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                {...titleIconWrapperProps}
              >
                {leftIcon && <Box marginEnd={leftIcon ? 2 : 0}>{leftIcon}</Box>}
                {title && (
                  <Heading variant={variant.Heading.pageTitle} as="h1">
                    {title}
                  </Heading>
                )}
                {infoTooltipText && (
                  <Tooltip placement="right" hasArrow label={infoTooltipText}>
                    <InfoOutlineIcon ml={2} mt={1} />
                  </Tooltip>
                )}
                {contentBesidesTitle}
              </Flex>
              {contentOnRightOfTitle}
            </Flex>
            <Box>
              <Text
                textStyle={textStyle["body.medium.light"]}
                fontSize="md"
                mt={2}
                {...descriptionWrapperProps}
              >
                {description}
              </Text>
            </Box>
            {children}
          </Box>
        )}
        {!disableScheduler && (!isTooSmall || navSize == "small") && (
          <motion.div
            animate={SchedulerBtnPos}
            transition={{
              duration: 0.1,
              ease: "easeOut", // "linear" "easeIn", "easeOut", "easeInOut" "circIn", "circOut", "circInOut" "backIn", "backOut", "backInOut" "anticipate"
            }}
            style={{
              position: "fixed",
              bottom: "20px",
              zIndex: chatContext?.isOpen && isTooSmall ? 100 : 200,
              width: "48px",
            }}
          >
            {/* {getAccount(session)?.isAdmin ? ( */}
            <AiIconButton
              {...{
                containerProps: {
                  w: "48px",
                  h: "48px",
                  mb: "2",
                  square: true,
                },
                iconProps: {
                  width: "18px",
                  height: "18px",
                },
                onClick: () => {
                  if (chatContext?.isOpen) {
                    chatContext?.onClose();
                  } else {
                    if (tweetContext?.isOpen && wontFit) {
                      tweetContext?.close();
                    }
                    chatContext?.onOpen();
                  }
                },
                size: "lg",
              }}
            />
            {/* <AnimatedBg
              {...{
                w: "48px",
                h: "48px",
                mb: "2",
                square: true,
              }}
            >
              <IconButton
                variant={variant.Button.secondaryAI}
                w="48px"
                {...{
                  border: "1px solid #A376BB22",
                  bg: "#A577dd08",
                  _hover: {
                    border: "1px solid #A376BB22",
                  },
                  _dark: {
                    borderColor: "#403278",
                  },
                }}
                size="lg"
                aria-label="Open chat assist"
                icon={<AiIcon {...{ glow: true, width: "18px", height: "18px" }} />}
                onClick={() => {
                  if (chatContext?.isOpen) {
                    chatContext?.onClose();
                  } else {
                    if (tweetContext?.isOpen && wontFit) {
                      tweetContext?.close();
                    }
                    chatContext?.onOpen();
                  }
                }}
              />
            </AnimatedBg> */}
            {!tweetContext.isFullModeOpen && (
              <IconButton
                variant={variant.Button.primary}
                size="lg"
                aria-label="Write a tweet"
                icon={
                  tweetContext.isOpen ? (
                    <Icon viewBox="0 0 14 11">
                      <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12109 2.87891L10.1172 4.875H5.25C4.75781 4.875 4.375 5.28516 4.375 5.75C4.375 6.24219 4.75781 6.625 5.25 6.625H10.1172L8.12109 8.64844C7.95703 8.8125 7.875 9.03125 7.875 9.25C7.875 9.49609 7.95703 9.71484 8.12109 9.87891C8.44922 10.2344 9.02344 10.2344 9.35156 9.87891L12.8516 6.37891C13.207 6.05078 13.207 5.47656 12.8516 5.14844L9.35156 1.64844C9.02344 1.29297 8.44922 1.29297 8.12109 1.64844C7.76562 1.97656 7.76562 2.55078 8.12109 2.87891ZM2.625 10.125V1.375C2.625 0.910156 2.21484 0.5 1.75 0.5C1.25781 0.5 0.875 0.910156 0.875 1.375V10.125C0.875 10.6172 1.25781 11 1.75 11C2.21484 11 2.625 10.6172 2.625 10.125Z"
                          fill="currentColor"
                        />
                      </svg>
                    </Icon>
                  ) : (
                    <WriteAPostSVGIcon />
                  )
                }
                onClick={() => {
                  if (chatContext?.isOpen && wontFit) {
                    chatContext?.onClose();
                  }
                  tweetContext.setIsOpen(!tweetContext.isOpen);
                }}
              />
            )}
          </motion.div>
        )}
      </motion.div>
      {/* {
                true && (
                    <Scheduler isSchedulerOpen={tweetContext.isOpen} nextSlot={tweetContext.nextSlot} />
                )
            } */}
      <Footer />
      {/* <Box
                position="fixed" bottom={5} left="50%" transform="translateX(-50%)"
                zIndex="10"
                pl={pl}
                pr={pr}
            >   
                <div dangerouslySetInnerHTML={{ __html: `<a href="https://www.producthunt.com/posts/taplio?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-taplio" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=317360&theme=${useColorModeValue("light", "dark")}" alt="Tweet Hunter - AI powered Twitter growth for makers | Product Hunt" style="width: 180px; height: 40px;" width="180" height="40" /></a>` }} />
            </Box> */}
      {/* TO ACTIVATE - ERROR MESSAGE */}
      {/* <Box
                position="fixed" bottom={2} left="50%" transform="translateX(-50%)"
                zIndex="10"
                pl={pl}
                pr={pr}
            >   
                <Tag
                    colorScheme="red"
                    size="sm"
                    fontSize="xs"
                    fontWeight="400"
                    textAlign="center"
                >
                    ⚠️ Some of our AI Models are having issues - we are working on fixing them
                </Tag>
            </Box>  */}
    </Box>
  );
};
