import { Icon } from "@chakra-ui/react";

export const HelpCenterSVGIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0.5C3.5625 0.5 0 4.09375 0 8.5C0 12.9375 3.5625 16.5 8 16.5C12.4062 16.5 16 12.9375 16 8.5C16 4.09375 12.4062 0.5 8 0.5ZM8 15C4.40625 15 1.5 12.0938 1.5 8.5C1.5 4.9375 4.40625 2 8 2C11.5625 2 14.5 4.9375 14.5 8.5C14.5 12.0938 11.5625 15 8 15ZM8 11C7.4375 11 7 11.4375 7 12C7 12.5625 7.40625 13 8 13C8.53125 13 9 12.5625 9 12C9 11.4375 8.53125 11 8 11ZM9.03125 4.5H7.4375C6.21875 4.5 5.25 5.46875 5.25 6.6875C5.25 7.09375 5.59375 7.4375 6 7.4375C6.40625 7.4375 6.75 7.09375 6.75 6.6875C6.75 6.3125 7.03125 6 7.40625 6H9C9.40625 6 9.75 6.3125 9.75 6.6875C9.75 6.9375 9.625 7.15625 9.40625 7.28125L7.625 8.34375C7.375 8.5 7.25 8.75 7.25 9V9.5C7.25 9.90625 7.59375 10.25 8 10.25C8.40625 10.25 8.75 9.90625 8.75 9.5V9.4375L10.1562 8.5625C10.8125 8.15625 11.2188 7.4375 11.2188 6.6875C11.25 5.46875 10.2812 4.5 9.03125 4.5Z"
      fill="currentColor"
    />
  </svg>
);
