import { memo, useState, useEffect } from "react";
import { Flex, useColorModeValue, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Header } from "./header";
import { MainNavItems } from "./mainNavItems";
import { Footer } from "./footer";
import { useWindowWidth } from "@react-hook/window-size";
import { setInLocalStorage } from "utils/helpers";

interface Props {
  navSize: "small" | "large";
  changeNavSize: (val: "small" | "large") => void;
  credits?: number;
  creditsAuto?: number;
  isOpenWalkthrough: boolean;
  onOpenWalkthrough: () => void;
  onCloseWalkthrough: () => void;
}

function isEqual(prevProps, nextProps) {
  if (
    prevProps.isOpenWalkthrough != nextProps.isOpenWalkthrough ||
    prevProps.navSize != nextProps.navSize ||
    prevProps.credits != nextProps.credits ||
    prevProps.creditsAuto != nextProps.creditsAuto ||
    prevProps.screenWidth != nextProps.screenWidth ||
    prevProps.isTooSmall != nextProps.isTooSmall
  ) {
    return false;
  }
  return true;
}

function Sidebar({
  navSize,
  changeNavSize,
  isOpenWalkthrough,
  onOpenWalkthrough,
  onCloseWalkthrough,
}: Props) {
  const initialValues = {
    height: "100%",
    background: useColorModeValue("white", "#1E1E1E"),
    border: useColorModeValue(
      "border.lightMode.light",
      "border.darkMode.light"
    ),
  };
  const [height, setHeight] = useState<string>(initialValues.height);
  const [background, setBackground] = useState<string>(
    initialValues.background
  );
  const [border, setBorder] = useState<string>(initialValues.border);
  const { colorMode } = useColorMode();
  const screenWidth = useWindowWidth();
  const isTooSmall = screenWidth < 800;
  const [withShadowFooter, setWithShadowFooter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem("navSize");
    if (savedState === "large") {
      setMenuOpen(true);
    }
  }, []);

  const sidebarWidth = isMenuOpen ? (isTooSmall ? "100%" : "250px") : "60px";

  const toggleMenu = () => {
    const newSize = isMenuOpen ? "small" : "large";
    setMenuOpen(!isMenuOpen);
    changeNavSize(newSize);
    setInLocalStorage("navSize", newSize);
  };

  useEffect(() => {
    if (isTooSmall && navSize === "small") {
      setHeight("60px");
      setBackground("transparent");
      setBorder("transparent");
    } else {
      setHeight(initialValues.height);
      setBackground(initialValues.background);
      setBorder(initialValues.border);
    }
  }, [isTooSmall, navSize, screenWidth, colorMode]);

  useEffect(() => {
    if (!isTooSmall) {
      setBackground(initialValues.background);
    }
  }, [colorMode]);

  useEffect(() => {
    const initialOffset = "0px";

    if (!isTooSmall) {
      document.body.style.marginLeft = isMenuOpen
        ? `calc(200px + ${initialOffset})`
        : initialOffset;
      document.body.style.transition = "margin-left 0.3s ease-out";
    } else {
      document.body.style.marginLeft = "60px";
    }

    return () => {
      document.body.style.marginLeft = initialOffset;
    };
  }, [isMenuOpen, isTooSmall]);

  return (
    <motion.div
      className="sidebar-container"
      animate={{
        width: sidebarWidth,
      }}
      transition={{
        duration: 0.1,
        ease: "easeOut",
      }}
      style={{
        height: "100vh",
        top: 0,
        left: 0,
        position: "fixed",
        zIndex: 99,
        overflow: "hidden",
      }}
      onMouseEnter={() => {
        if (!isMenuOpen) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Flex
        h="100%"
        w="100%"
        flexDirection="column"
        bg={background}
        borderRight="1px solid"
        borderColor={border}
      >
        <Header
          navSize={isMenuOpen ? "large" : "small"}
          changeNavSize={changeNavSize}
          toggleMenu={toggleMenu}
          isMenuOpen={isMenuOpen}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
        />
        <MainNavItems
          navSize={isMenuOpen ? "large" : "small"}
          changeNavSize={changeNavSize}
          setWithShadowFooter={setWithShadowFooter}
          setMenuOpen={setMenuOpen}
        />
        <Footer
          navSize={isMenuOpen ? "large" : "small"}
          changeNavSize={changeNavSize}
          isOpenWalkthrough={isOpenWalkthrough}
          onOpenWalkthrough={onOpenWalkthrough}
          onCloseWalkthrough={onCloseWalkthrough}
          withShadowFooter={withShadowFooter}
        />
      </Flex>
    </motion.div>
  );
}

export default memo(Sidebar, isEqual);
