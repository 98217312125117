import { Box, color, Flex, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";

interface Props {
  isLoading: boolean;
  duration?: number;
  height?: number;
  colorLight?: string;
  colorDark?: string;
}
export const LoadingBar = ({
  isLoading = false,
  duration = 600,
  height = 4,
  colorLight = "primary.lightMode.default",
  colorDark = "primary.darkMode.default",
}: Props) => {
  const [loading, setLoading] = useState(isLoading);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const barColor = useColorModeValue(colorLight, colorDark);
  useEffect(() => {
    setLoading(isLoading);
    if (isLoading) {
      setProgress(0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (loading) {
      intervalRef.current =
        intervalRef.current === null
          ? setInterval(() => {
              setProgress((prev) => Math.min(prev + 10, 81));
            }, duration)
          : intervalRef.current;
    }
    if (!loading && progress >= 30 && intervalRef.current) {
      setProgress(100);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setTimeout(() => {
        setProgress(0);
      }, duration);
    }
  }, [loading, progress, intervalRef]);

  return (
    <Flex h={height + "px"}>
      <Box
        w={`${progress}%`}
        h="100%"
        bg={barColor}
        transition={"width 0.2s linear"}
      />
    </Flex>
  );
};
