import React from "react";
import { Icon } from "@chakra-ui/react";
import Image from "next/image";
import th from "./tweethunter.png"
export const TweetHunterIcon = ({ ...props }) => (
  <Image src={th} alt="tweethunter" width={42} height={42} />
  // <Icon
  //   {...{
  //     width: 42,
  //     height: 43,
  //     viewBox: "0 0 42 43",
  //     ...props,
  //   }}
  // >
  //   <svg
  //     width="42"
  //     height="43"
  //     viewBox="0 0 42 43"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M38.0625 21.5C38.0625 30.9234 30.4234 38.5625 21 38.5625C11.5766 38.5625 3.9375 30.9234 3.9375 21.5C3.9375 12.0766 11.5766 4.4375 21 4.4375C30.4234 4.4375 38.0625 12.0766 38.0625 21.5Z"
  //       fill="#38A1D6"
  //     />
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M21 41.1875C31.8731 41.1875 40.6875 32.3731 40.6875 21.5C40.6875 10.6269 31.8731 1.8125 21 1.8125C10.1269 1.8125 1.3125 10.6269 1.3125 21.5C1.3125 32.3731 10.1269 41.1875 21 41.1875ZM21 42.5C32.598 42.5 42 33.098 42 21.5C42 9.90202 32.598 0.5 21 0.5C9.40202 0.5 0 9.90202 0 21.5C0 33.098 9.40202 42.5 21 42.5Z"
  //       fill="#3D41A6"
  //     />
  //     <path
  //       d="M31.5 21.5C31.5 27.299 26.799 32 21 32C15.201 32 10.5 27.299 10.5 21.5C10.5 15.701 15.201 11 21 11C26.799 11 31.5 15.701 31.5 21.5Z"
  //       fill="#3D41A6"
  //     />
  //   </svg>
  // </Icon>
);
