import React, { useState } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuDivider,
  Portal,
  Box,
} from "@chakra-ui/react";
import { LemListFamilyIcon } from "./icons/LemListFamilyIcon";
import { LemSwitcherMenuItem } from "./LemSwitcherMenuItem";
import { TaplioIcon } from "./icons/TaplioIcon";
import { LemWarmIcon } from "./icons/LemWarmIcon";
import { LemListIcon } from "./icons/LemListIcon";
import { TweetHunterIcon } from "./icons/TweetHunterIcon";
import lemcalred from "./icons/lemcalred.png";
import lemcalwhite from "./icons/lemcalwhite.png";
import Image from "next/image";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const LemSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Menu variant="switcher" autoSelect={false}>
      <MenuButton
        as={IconButton}
        {...{
          variant: "action",
          position: "absolute",
          top: "14px",
          left: "130px",
          fontSize: "30px",
          _hover: { backgroundColor: "transparent" },
          _active: { backgroundColor: "transparent" },
          _focus: { boxShadow: "none" },
        }}
        aria-label="App Switcher"
        icon={<ChevronDownIcon />}
        zIndex={9999}
        onClick={(e) => {
          setIsOpen(!isOpen);
          if (!isOpen) {
            console.log("hit lemapp swticher", e);
          }
        }}
      />

      <Portal>
        <Box position={"fixed"} zIndex={9999}>
          <MenuList
            {...{
              zIndex: 9999,
              maxW: ["320px", "none"],
              position: "absolute",
              left: "-110px",
              width: "340px",
            }}
          >
            <LemSwitcherMenuItem
              {...{
                title: "Taplio",
                subTitle: "Grow your personal brand",
                icon: <TaplioIcon />,
                href: undefined,
                current: true,
              }}
            />
            {[
              {
                title: "Tweet Hunter",
                subTitle: "Build & monetize your Twitter audience",
                icon: <TweetHunterIcon />,
                href: "https://app.tweethunter.io/",
              },
              {
                title: "lemlist",
                subTitle: "Start conversations that get replies",
                icon: <LemListIcon />,
                href: "https://app.lemlist.com/",
              },
              {
                title: "lemwarm",
                subTitle: "Keep your emails away from spam",
                icon: <LemWarmIcon />,
                href: "https://app.lemwarm.com/",
              },
              {
                title: "lemcal",
                subTitle: "Book meetings in the easiest way ever",
                icon: (
                  <Image
                    src={lemcalred}
                    alt="lemcal icon"
                    width={"42"}
                    height={"42"}
                  />
                ),
                iconHover: (
                  <Image
                    src={lemcalwhite}
                    alt="lemcal icon"
                    width={"42"}
                    height={"42"}
                  />
                ),
                href: "https://www.lemcal.com/",
              },
            ].map((item, mapIndex) => (
              <LemSwitcherMenuItem key={mapIndex} {...{ ...item }} />
            ))}
            <MenuDivider />
            <LemSwitcherMenuItem
              {...{
                hover: true,
                title: "lemfam",
                subTitle: "Join the 1% of Sales & Marketers",
                icon: <LemListFamilyIcon />,
                href: "https://community.lemlistfamily.com/",
              }}
            />
          </MenuList>
        </Box>
      </Portal>
    </Menu>
  );
};
