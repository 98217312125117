import { ListItem, ListIcon, Tooltip, Link, Button } from "@chakra-ui/react";
import { MdCheckCircle, MdOutlineCircle } from "react-icons/md";
import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { color, textStyle } from "theme/names";

export type TaskTypes =
  | "aiInpiration"
  | "searchInspiration"
  | "aiWriting"
  | "schedule"
  | "evergreen"
  | "engage";
export interface Task {
  id: TaskTypes;
  title: string;
  helperText: string;
  isComplete?: boolean;
  link?: string;
  isSkipable?: boolean;
  handleSkip?: () => void;
}

export function CustomListItem({
  id,
  title,
  helperText,
  isComplete,
  link,
  isSkipable = false,
  handleSkip = () => {},
}: Task) {
  return (
    <ListItem
      textStyle={textStyle["body.medium.standard"]}
    >
      <ListIcon
        as={isComplete ? MdCheckCircle : MdOutlineCircle}
        color={color["success.lightMode.default"]}
      />
      {link ? (
        <Link href={link} textDecoration="underline">
          {title}
        </Link>
      ) : (
        title
      )}
      <Tooltip hasArrow label={helperText}>
        <ListIcon
          as={QuestionOutlineIcon}
          // color="gray.500"
        color={color["text.lightMode.light"]}
        marginInlineStart="2"
        />
      </Tooltip>
      {isSkipable && (
        <Button size="sm" variant="ghost" onClick={() => handleSkip()}>
          Skip
        </Button>
      )}
    </ListItem>
  );
}
